import {
  ManualRemediationType,
  RulesRemediationDto,
  RulesRemediationExclusionDto,
  RulesRemediationTypesFileDto,
  SiteGroupType,
  SiteGroupVersion,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { rulesRemediationsApi, rulesRemediationsTypesApi } from 'services/api/ruleRemediations.api';

export enum RemediationKey {
  RemediationStableId = 'remediationStableId',
}

export type UseGetRuleRemediationsArs = {
  siteGroupId?: string;
  siteGroupType: SiteGroupType;
  reportingVersion?: string;
};

const OVERVIEW_QUERY_KEY = 'useGetSiteGroupOverview';

export const siteGroupOverviewKey = (
  siteGroupId: string | undefined,
  siteGroupVersion: SiteGroupVersion = SiteGroupVersion.WORKING,
) => [OVERVIEW_QUERY_KEY, siteGroupId, siteGroupVersion];

export const getFilesKey = (
  siteGroupId: string | undefined,
  siteGroupVersion: SiteGroupVersion,
): (string | undefined)[] => ['rules_files', siteGroupId, siteGroupVersion];

export const useGetRemediationV2 = ({
  stableId,
  siteGroupVersion,
}: {
  stableId: string | undefined;
  siteGroupVersion: SiteGroupVersion | undefined;
}): UseQueryResult<RulesRemediationDto> => {
  return useQuery({
    queryKey: [RemediationKey.RemediationStableId, { stableId, siteGroupVersion }],
    queryFn: () =>
      rulesRemediationsApi.getRemediationV2(nullthrows(stableId), nullthrows(siteGroupVersion)).then((res) => res.data),
    enabled: stableId != null && siteGroupVersion != null,
  });
};

export const useGetSiteGroupRulesOverview = ({
  siteGroupId,
  siteGroupVersion,
}: {
  siteGroupId: string | undefined;
  siteGroupVersion: SiteGroupVersion | undefined;
}): UseQueryResult<RulesRemediationDto[]> => {
  return useQuery({
    queryKey: siteGroupOverviewKey(siteGroupId, siteGroupVersion),
    queryFn: () =>
      rulesRemediationsApi
        .getOverviewForSiteGroup(nullthrows(siteGroupId), nullthrows(siteGroupVersion))
        .then((res) => res.data),
    enabled: siteGroupId != null && siteGroupVersion != null,
  });
};

export const useCreateExclusion = (): UseMutationResult<
  RulesRemediationExclusionDto,
  AxiosError,
  { siteGroupId: string; stableId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, stableId }) =>
      rulesRemediationsApi.addExclusion(siteGroupId, stableId).then((axiosResponse) => axiosResponse.data),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useDeleteExclusion = (): UseMutationResult<
  void,
  AxiosError,
  { siteGroupId: string; stableId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, stableId }) =>
      rulesRemediationsApi.deleteExclusion(siteGroupId, stableId).then((axiosResponse) => axiosResponse.data),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};
export const useCreateRulesRemediation = (): UseMutationResult<
  RulesRemediationDto,
  AxiosError,
  {
    siteGroupId: string;
    contents: string;
    name?: string;
    type?: ManualRemediationType;
    viewingSiteGroupId: string;
    ruleName?: string;
  }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ siteGroupId, contents, name, type = ManualRemediationType.RULE, ruleName }) =>
      (await rulesRemediationsApi.addRemediation(siteGroupId, { name, type, contents, ruleName })).data,
    onSuccess: async (_, { viewingSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(viewingSiteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(viewingSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useUpdateRulesRemediation = (): UseMutationResult<
  RulesRemediationDto,
  AxiosError,
  { name?: string; fileId: string; contents: string; viewingSiteGroupId: string; ruleName?: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId, name, contents, ruleName }) =>
      (await rulesRemediationsApi.updateRemediationV2(fileId, { name, contents, ruleName })).data,
    onSuccess: async (_, { viewingSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(viewingSiteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(viewingSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useRulesRemediationTypes = (
  siteGroupId: string | undefined,
): UseQueryResult<RulesRemediationTypesFileDto[]> =>
  useQuery({
    queryKey: ['rulesRemsTypes', siteGroupId],
    queryFn: async () => (await rulesRemediationsTypesApi.getTypesForSiteGroup(nullthrows(siteGroupId))).data,
    enabled: siteGroupId != null,
  });

export const useDeleteRemediationFile = (): UseMutationResult<
  void,
  AxiosError,
  { siteGroupId: string; fileId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId }) => {
      await rulesRemediationsApi.deleteFile(fileId);
    },
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useDeleteRemediationFiles = (): UseMutationResult<
  void,
  AxiosError,
  { siteGroupIds: string[]; fileIds: string[] }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileIds }) => {
      await rulesRemediationsApi.deleteFiles(fileIds);
    },
    onSuccess: async (_, { siteGroupIds }) => {
      await Promise.all(
        siteGroupIds.map(async (siteGroupId) => {
          await queryClient.invalidateQueries({
            queryKey: siteGroupOverviewKey(siteGroupId),
          });
          await queryClient.invalidateQueries({
            queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
          });
        }),
      );
    },
  });
};
