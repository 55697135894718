import { ComponentConfigurationDTO, ComponentSiteFilterType, PortalComponentType } from '@audioeye/mono-client';

import { Gate } from '../types/util';

export type ComponentLink = Omit<ComponentConfigurationDTO, 'type'>;
type ComponentLinkConfig = [PortalComponentType, ComponentLink];

const DashboardLink: ComponentLinkConfig = [
  PortalComponentType.DASHBOARD,
  { label: 'Dashboard', iconType: 'bar-chart' },
];
const ReportLink: ComponentLinkConfig = [
  PortalComponentType.REPORTS,
  {
    label: 'Reports',
    iconType: 'compliance',
    componentFilter: {
      // TODO RES-741 (@kprice-ae) fast-follow: just use INACTIVE here... no need for both
      siteFilters: [{ type: ComponentSiteFilterType.IS_FREE_OR_LAPSED_OR_CANCELLED, invertResult: true }],
    },
  },
];
const VisualToolkitLink: ComponentLinkConfig = [
  PortalComponentType.VISUAL_TOOLKIT,
  {
    label: 'Visual Toolkit',
    iconType: 'accessibility',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_INACTIVE, invertResult: true }],
    },
  },
];

const ReportConfigurationLink: ComponentLinkConfig = [
  PortalComponentType.REPORT_URL_CONFIGURATION,
  {
    label: 'Report configuration',
    iconType: 'performance',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_ON_RULES_BASED_AUTO_REMS }],
    },
  },
];

const InstallationLink: ComponentLinkConfig = [
  PortalComponentType.INSTALLATION,
  {
    label: 'Installation',
    iconType: 'configure',
  },
];
const DocumentationLink: ComponentLinkConfig = [
  PortalComponentType.DOCUMENTATION,
  {
    label: 'Legal resources',
    iconType: 'legal-documents',
  },
];
const LegalSupportLink: ComponentLinkConfig = [
  PortalComponentType.LEGAL_SUPPORT,
  {
    label: 'Request legal help',
    iconType: 'legal',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_INACTIVE, invertResult: true }],
      featureGate: { name: Gate.ShowLegalSupport },
    },
  },
];
const EducationLink: ComponentLinkConfig = [
  PortalComponentType.EDUCATION,
  {
    label: 'Education',
    iconType: 'video',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_INACTIVE, invertResult: true }],
    },
  },
];
const StarPlanLink: ComponentLinkConfig = [
  PortalComponentType.STAR_PLAN,
  {
    label: 'STAR Plan',
    iconType: 'star',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_INACTIVE, invertResult: true }],
    },
  },
];
const A11yStatementLink: ComponentLinkConfig = [
  PortalComponentType.ACCESSIBILITY_STATEMENT,
  {
    label: 'Accessibility Statement',
    iconType: 'accessibility-statement',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_INACTIVE, invertResult: true }],
    },
  },
];
const TrustedCertificationLink: ComponentLinkConfig = [
  PortalComponentType.TRUSTED_CERTIFICATION,
  {
    label: 'Trusted Certification',
    iconType: 'certificate',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_INACTIVE, invertResult: true }],
    },
  },
];
const A11yScannerLink: ComponentLinkConfig = [
  PortalComponentType.ACCESSIBILITY_SCANNER,
  {
    label: 'Accessibility scanner',
    iconType: 'scan',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_FREE_OR_LAPSED_OR_CANCELLED, invertResult: true }],
    },
  },
];
const SubscriptionLink: ComponentLinkConfig = [
  PortalComponentType.SUBSCRIPTION,
  {
    label: 'Subscription',
    iconType: 'update',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.IS_ENTERPRISE_SITE, invertResult: true }],
      featureGate: { name: Gate.CanManageSubscriptions },
    },
  },
];
const ExpertAuditLink: ComponentLinkConfig = [
  PortalComponentType.EXPERT_AUDIT,
  {
    label: 'Expert audits',
    iconType: 'ruler',
    componentFilter: {
      siteFilters: [{ type: ComponentSiteFilterType.HAS_EXPERT_AUDIT_DATA }],
    },
  },
];

const currentNavOrder: Map<PortalComponentType, Omit<ComponentConfigurationDTO, 'type'>> = new Map([
  DashboardLink,
  ReportLink,
  VisualToolkitLink,
  ReportConfigurationLink,
  InstallationLink,
  LegalSupportLink,
  EducationLink,
  StarPlanLink,
  A11yStatementLink,
  TrustedCertificationLink,
  A11yScannerLink,
  SubscriptionLink,
]);

const newMapOrder: Map<PortalComponentType, Omit<ComponentConfigurationDTO, 'type'>> = new Map([
  DashboardLink,
  ReportLink,
  A11yScannerLink,
  VisualToolkitLink,
  ReportConfigurationLink,
  InstallationLink,
  ExpertAuditLink,
  DocumentationLink,
  StarPlanLink,
  A11yStatementLink,
  TrustedCertificationLink,
  LegalSupportLink,
  EducationLink,
  SubscriptionLink,
]);

/**
 * @description
 * This is the default configuration for the presentation config.
 * It controls the order and visibility of the Navigation Menu items that appear on the left side of Portal
 */

// [Map](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map) maintains insertion order
export const DEFAULT_PRESENTATION_CONFIG_COMPONENT_VALUES: Map<
  PortalComponentType,
  Omit<ComponentConfigurationDTO, 'type'>
> = currentNavOrder;

// TODO RES-741: wherever DEFAULT_PRESENTATION_CONFIG_COMPONENT_VALUES is used, need to check feature gate
// and use this one when shouldShowNavChanges is true
export const NEW_PRESENTATION_CONFIG_COMPONENT_VALUES: Map<
  PortalComponentType,
  Omit<ComponentConfigurationDTO, 'type'>
> = newMapOrder;

export const getLabelForComponentSiteFilterType = (type: ComponentSiteFilterType): string => {
  switch (type) {
    case ComponentSiteFilterType.IS_FREE_OR_LAPSED_OR_CANCELLED: {
      return 'free, lapsed, or cancelled site';
    }
    case ComponentSiteFilterType.IS_INACTIVE: {
      return 'inactive site';
    }
    case ComponentSiteFilterType.IS_ENTERPRISE_SITE: {
      return 'enterprise site';
    }
    case ComponentSiteFilterType.IS_SITE_CODE_DETECTED: {
      return 'site code detected';
    }
    case ComponentSiteFilterType.HAS_EXPERT_AUDIT_DATA: {
      return 'has expert audit data';
    }
    case ComponentSiteFilterType.IS_ON_RULES_BASED_AUTO_REMS: {
      return 'on rules based auto rems';
    }
    default: {
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
    }
  }
};

/**
 * @deprecated
 * Retained for our mock UI Configs to still function. Don't use in production code
 */
export const CONVERT_DEFAULT_COMPONENT_VALUES_TO_DTO = (): ComponentConfigurationDTO[] =>
  Array.from(DEFAULT_PRESENTATION_CONFIG_COMPONENT_VALUES, ([key, value]) => ({ type: key, ...value }));
