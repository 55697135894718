'use strict';

var joy = require('@mui/joy');
var styles = require('@mui/joy/styles');

const dialogThemeComponents = {
    JoyModalDialog: {
        defaultProps: {
            variant: 'outlined',
            maxWidth: 'md',
        },
        styleOverrides: {
            root: {
                padding: 0,
                gap: 0,
            },
        },
    },
    JoyDialogTitle: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(1.5),
                fontSize: theme.fontSize.lg,
                fontWeight: theme.fontWeight.lg,
                lineHeight: theme.lineHeight.lg,
                // divider line on bottom
                boxShadow: `inset 0 -1px ${theme.palette.divider}`,
            }),
        },
    },
    JoyDialogContent: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(1.5),
            }),
        },
    },
    JoyDialogActions: {
        defaultProps: {
            buttonFlex: '0 0 150px',
        },
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(1.5),
                // divider line on top
                boxShadow: `inset 0 1px ${theme.palette.divider}`,
            }),
        },
    },
    JoyModalClose: {
        defaultProps: {
            variant: 'plain',
            'aria-label': 'close',
        },
    },
};

/* eslint-disable @typescript-eslint/naming-convention */
// Leave the colors defined in here for now. If in the future a use case arises where we want something like
// `palette['primary-600']` to be used outside of this theme, we can set up a colors file.
const joyTheme = styles.extendTheme({
    components: {
        JoySnackbar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    maxWidth: theme.breakpoints.values.sm,
                }),
            },
        },
        JoyFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 'var(--joy-fontSize-xs)',
                },
            },
        },
        JoySelect: {
            styleOverrides: {
                root: {
                    '--Select-minHeight': '42px',
                },
            },
        },
        JoyModal: {
            styleOverrides: {
                backdrop: {
                    backdropFilter: 'none',
                    filter: 'none',
                },
            },
        },
        JoyTabs: {
            styleOverrides: {
                root: ({ theme }) => ({
                    px: 0,
                    backgroundColor: 'unset',
                    [`& .${joy.tabListClasses.root}`]: {
                        fontWeight: 'md',
                    },
                    [`& .${joy.tabClasses.root}`]: {
                        fontSize: 'sm',
                        [`&.${joy.tabClasses.selected}`]: {
                            color: theme.palette.primary['500'],
                            backgroundColor: 'unset',
                            fontWeight: 'bold',
                        },
                        [`&.${joy.tabClasses.focusVisible}`]: {
                            outlineOffset: '-3px',
                        },
                    },
                }),
            },
        },
        JoyTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        JoyTab: {
            styleOverrides: {
                root: {
                    '--Tab-indicatorThickness': '3px',
                },
            },
        },
        JoyLink: {
            defaultProps: {
                underline: 'always',
            },
            styleOverrides: {
                root: {
                    '&:hover': {
                        textDecoration: 'none',
                    },
                },
            },
        },
        JoyInput: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    '--Input-minHeight': '2.5rem',
                    [`&.${joy.inputClasses.sizeLg}`]: {
                        '--Input-minHeight': '3.25rem',
                    },
                },
            },
        },
        JoyMenuItem: {
            styleOverrides: {
                root: {
                    borderRadius: 'var(--joy-radius-sm)',
                    marginInline: 'var(--joy-spacing)',
                    '&:active, &:hover': {
                        backgroundColor: 'var(--joy-palette-neutral-50)',
                    },
                    '&[aria-current="page"]': {
                        backgroundColor: 'var(--joy-palette-neutral-50)',
                        color: 'var(--joy-palette-common-white)',
                    },
                },
            },
        },
        JoyTable: {
            styleOverrides: {
                root: {
                    '& thead th': {
                        fontSize: 'var(--joy-fontSize-md)',
                        fontWeight: 'var(--joy-fontWeight-lg)',
                    },
                },
            },
        },
        // TODO PORTAL-5917: fix drawer size.
        JoyDrawer: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.size === 'xl' && {
                        width: '80%',
                    }),
                }),
                backdrop: {
                    backdropFilter: 'blur(0)',
                    filter: 'blur(0)',
                },
            },
        },
        ...dialogThemeComponents,
    },
    typography: {
        h1: {
            fontFamily: 'Schibsted Grotesk',
            fontSize: '3rem',
            fontWeight: 600,
        },
        h2: {
            fontFamily: 'Schibsted Grotesk',
            fontSize: '2rem',
            fontWeight: 700,
        },
        h3: {
            fontFamily: 'Schibsted Grotesk',
            fontSize: '1.5rem',
            fontWeight: 700,
        },
        h4: {
            fontFamily: 'Schibsted Grotesk',
            fontSize: '1.25rem',
            fontWeight: 600,
        },
    },
    fontFamily: {
        body: 'Inter', // applies to `title-*` and `body-*`
    },
    radius: {
        xs: '0.125rem',
        sm: '0.25rem',
        md: '0.5rem',
        lg: '1rem',
        xl: '2rem',
    },
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    '50': '#F7F5FF',
                    '100': '#EDEBFE',
                    '200': '#DCD6FF',
                    '300': '#BCB2FF',
                    '400': '#A18FFF',
                    '500': '#8055F7',
                    '600': '#602ECC',
                    '700': '#48189A',
                    '800': '#320A6C',
                    '900': '#1D0240',
                },
                neutral: {
                    '50': '#F7F8FD',
                    '100': '#ECEFF8',
                    '200': '#D9DDED',
                    '300': '#B0B7CF',
                    '400': '#8D95B0',
                    '500': '#6C758E',
                    '600': '#4A5169',
                    '700': '#363C4F',
                    '800': '#242938',
                    '900': '#191D29',
                },
                danger: {
                    '50': '#FFF3F0',
                    '100': '#FFDFD6',
                    '200': '#FFBAA8',
                    '300': '#FF9D84',
                    '400': '#FB7F60',
                    '500': '#CF3F17',
                    '600': '#A52D08',
                    '700': '#7F1F00',
                    '800': '#521600',
                    '900': '#2E0800',
                },
                success: {
                    '50': '#EDFDF5',
                    '100': '#DBFAE9',
                    '200': '#BBF7D5',
                    '300': '#84E1AC',
                    '400': '#46BE7A',
                    '500': '#0E864E',
                    '600': '#0E6235',
                    '700': '#0B4726',
                    '800': '#062D17',
                    '900': '#021D0F',
                },
                warning: {
                    '50': '#FFF6E5',
                    '100': '#FFEBC2',
                    '200': '#FFD484',
                    '300': '#F6BB55',
                    '400': '#D99726',
                    '500': '#A16C00',
                    '600': '#805300',
                    '700': '#5C3C00',
                    '800': '#3D2900',
                    '900': '#241900',
                },
                info: {
                    '50': '#F0F5FF',
                    '100': '#DBE8FF',
                    '200': '#B8D2FF',
                    '300': '#94BBFF',
                    '400': '#6B9FFF',
                    '500': '#356EE9',
                    '600': '#1B4DC0',
                    '700': '#0F328A',
                    '800': '#012260',
                    '900': '#011737',
                    // The following "global variant tokens" are used to style Joy components based on
                    //  the `variant` parameter when using the `info` color palette.
                    // Read more on those tokens here:
                    //  - https://mui.com/joy-ui/customization/theme-colors/#global-variant-tokens
                    //  - https://mui.com/joy-ui/customization/theme-colors/#adding-new-palettes
                    // plain variant
                    plainColor: 'var(--joy-palette-info-500)',
                    plainHoverBg: 'var(--joy-palette-info-100)',
                    plainActiveBg: 'var(--joy-palette-info-200)',
                    plainDisabledColor: 'var(--joy-palette-neutral-400)',
                    // outlined variant
                    outlinedColor: 'var(--joy-palette-info-500)',
                    outlinedBorder: 'var(--joy-palette-info-300)',
                    outlinedHoverBg: 'var(--joy-palette-info-100)',
                    outlinedActiveBg: 'var(--joy-palette-info-200)',
                    outlinedDisabledColor: 'var(--joy-palette-info-400)',
                    outlinedDisabledBorder: 'var(--joy-palette-info-200)',
                    // soft variant
                    softColor: 'var(--joy-palette-info-700)',
                    softBg: 'var(--joy-palette-info-100)',
                    softHoverBg: 'var(--joy-palette-info-200)',
                    softActiveBg: 'var(--joy-palette-info-300)',
                    softDisabledColor: 'var(--joy-palette-info-400)',
                    softDisabledBg: 'var(--joy-palette-info-50)',
                    // solid variant
                    solidColor: 'var(--joy-palette-common-white)',
                    solidBg: 'var(--joy-palette-info-500)',
                    solidHoverBg: 'var(--joy-palette-info-600)',
                    solidActiveBg: 'var(--joy-palette-info-700)',
                    solidDisabledColor: 'var(--joy-palette-info-400)',
                    solidDisabledBg: 'var(--joy-palette-info-100)',
                },
                secondary: {
                    '50': '#EEFCFC',
                    '100': '#D6F5F5',
                    '200': '#ADEBE7',
                    '300': '#7DD8D5',
                    '400': '#3CB4B0',
                    '500': '#078381',
                    '600': '#005F61',
                    '700': '#004447',
                    '800': '#083235',
                    '900': '#022327',
                    // The following "global variant tokens" are used to style Joy components based on
                    //  the `variant` parameter when using the `info` color palette.
                    // Read more on those tokens here:
                    //  - https://mui.com/joy-ui/customization/theme-colors/#global-variant-tokens
                    //  - https://mui.com/joy-ui/customization/theme-colors/#adding-new-palettes
                    // plain variant
                    plainColor: 'var(--joy-palette-secondary-500)',
                    plainHoverBg: 'var(--joy-palette-secondary-100)',
                    plainActiveBg: 'var(--joy-palette-secondary-200)',
                    plainDisabledColor: 'var(--joy-palette-neutral-400)',
                    // outlined variant
                    outlinedColor: 'var(--joy-palette-secondary-500)',
                    outlinedBorder: 'var(--joy-palette-secondary-300)',
                    outlinedHoverBg: 'var(--joy-palette-secondary-100)',
                    outlinedActiveBg: 'var(--joy-palette-secondary-200)',
                    outlinedDisabledColor: 'var(--joy-palette-secondary-400)',
                    outlinedDisabledBorder: 'var(--joy-palette-secondary-200)',
                    // soft variant
                    softColor: 'var(--joy-palette-secondary-700)',
                    softBg: 'var(--joy-palette-secondary-100)',
                    softHoverBg: 'var(--joy-palette-secondary-200)',
                    softActiveBg: 'var(--joy-palette-secondary-300)',
                    softDisabledColor: 'var(--joy-palette-secondary-400)',
                    softDisabledBg: 'var(--joy-palette-secondary-50)',
                    // solid variant
                    solidColor: 'var(--joy-palette-common-white)',
                    solidBg: 'var(--joy-palette-secondary-500)',
                    solidHoverBg: 'var(--joy-palette-secondary-600)',
                    solidActiveBg: 'var(--joy-palette-secondary-700)',
                    solidDisabledColor: 'var(--joy-palette-secondary-400)',
                    solidDisabledBg: 'var(--joy-palette-secondary-100)',
                },
                background: {
                    body: '#F5F5F5', // This is the default background color for the body
                    surface: 'var(--joy-palette-common-white)',
                    popup: 'var(--joy-palette-common-white)',
                    level1: 'var(--joy-palette-neutral-50)',
                    level2: 'var(--joy-palette-neutral-100)',
                    level3: 'var(--joy-palette-neutral-200)',
                },
                text: {
                    icon: 'var(--joy-palette-neutral-500)',
                    primary: 'var(--joy-palette-neutral-900)',
                    secondary: 'var(--joy-palette-neutral-800)',
                    tertiary: 'var(--joy-palette-neutral-500)',
                },
            },
        },
    },
});

exports.joyTheme = joyTheme;
